import React from "react";
import { Navbar } from "react-bootstrap";
import OwlcamLogo from "./OwlcamLogo";

export default function MainNavBar() {
  return (
    <Navbar className="owl-nav-bar" variant="dark">
      <Navbar.Brand>
        <b>CRASH REPORT</b>
      </Navbar.Brand>
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text>
          <OwlcamLogo height="35" fill="#ffffff" />
        </Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  );
}
