import React, { Component } from "react";
import "./App.css";
import MainNavBar from "./components/MainNavBar";
import SubNavBar from "./components/SubNavBar";
import Footer from "./components/Footer";
import PinForm from "./components/PinForm";
import ImageList from "./components/ImageList";
import VideoList from "./components/VideoList";
import APIConfiguration from "./configuration/config.json";

export const DEBUG = true;

const FORBIDDEN_TEXT = "Forbidden - check the pin and try again";
const LOCKED_TEXT = "Report locked - contact support@owlcam.com";
const QUOTA_EXCEED_TEXT = "Exceeded quota - contact support@owlcam.com";
const NO_PIN = "Please enter a valid pin and try again";
const ERROR_IN_DRAFT_TEXT = "In draft - submit report and try again";
const TRANSCODE_NOT_READY_TEXT = "Still transcoding - please try again";

const API_URL = APIConfiguration.API_URL;
const API_KEY = APIConfiguration.API_KEY;

const HTTP_STATUS_CODE_OK = 200;
const HTTP_STATUS_IN_DRAFT = 409;
const HTTP_STATUS_CODE_TRANSCODING = 418;
const HTTP_STATUS_CODE_LOCKED = 423;
const REQUEST_QUOTA_EXCEEDED = 429;

function getIdFromUrl() {
  return window.location.pathname.split("/")[1];
}

const resetState = {
  videos: null,
  error: null,
  images: null,
  timestamp: null,
  busy: null
};

export default class App extends Component {
  state = resetState;

  handleSubmit = input => {
    this.setState({ busy: true });
    if (DEBUG) {
      console.log("submit: " + input);
    }

    if (!input) {
      this.setState({ error: NO_PIN });
    } else {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify({
          alias: getIdFromUrl().toUpperCase(),
          key: input.toUpperCase()
        }),
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": API_KEY
        }
      })
        .then(response => {
          if (DEBUG) {
            console.log("Response status " + response.status);
          }

          if (response.status === HTTP_STATUS_CODE_OK) {
            return response.json();
          }
          if (response.status === HTTP_STATUS_CODE_LOCKED) {
            this.setState(resetState);
            this.setState({ error: LOCKED_TEXT });
            throw new Error(LOCKED_TEXT);
          } else if (response.status === HTTP_STATUS_CODE_TRANSCODING) {
            this.setState(resetState);
            this.setState({ error: TRANSCODE_NOT_READY_TEXT });
            throw new Error(TRANSCODE_NOT_READY_TEXT);
          } else if (response.status === REQUEST_QUOTA_EXCEEDED) {
            this.setState(resetState);
            this.setState({ error: QUOTA_EXCEED_TEXT });
            throw new Error(QUOTA_EXCEED_TEXT);
          } else if (response.status === HTTP_STATUS_IN_DRAFT) {
            this.setState(resetState);
            this.setState({ error: ERROR_IN_DRAFT_TEXT });
            throw new Error(ERROR_IN_DRAFT_TEXT);
          } else {
            this.setState(resetState);
            this.setState({ error: FORBIDDEN_TEXT });
            throw new Error(FORBIDDEN_TEXT);
          }
        })
        .then(responseJson => {
          //this.setState({ error: null, videos: responseJson.videos });
          if (DEBUG) {
            console.log("GOT: ");
            console.log(responseJson);
          }
          this.setState({
            error: null,
            images: responseJson.images,
            videos: responseJson.videos,
            // [
            //   {
            //     inline:
            //       "https://file-examples.com/wp-content/uploads/2017/04/file_example_MP4_480_1_5MG.mp4",
            //     attachment: "http://owlcam.com"
            //   }
            // ],
            timestamp: responseJson.reportTimestamp,
            busy: null
          });
        })
        .catch(err => {
          console.log(err);
          if (!this.state.error) {
            this.setState({ error: FORBIDDEN_TEXT });
          }
        });
    }
  };

  getCenterContent = () => {
    if (this.state.videos && !this.state.error) {
      return (
        <div>
          <VideoList videos={this.state.videos} />
          <ImageList images={this.state.images} />
        </div>
      );
    } else {
      return (
        <PinForm
          id={getIdFromUrl()}
          onSubmit={this.handleSubmit}
          error={this.state.error}
        />
      );
    }
  };

  render() {
    return (
      <div className="App">
        <MainNavBar />
        <SubNavBar id={getIdFromUrl()} timestamp={this.state.timestamp} />
        <center>{this.getCenterContent()}</center>
        <Footer />
      </div>
    );
  }
}
