import React from "react";

function formatDate(ts) {
  var dateFormat = require("dateformat");
  return dateFormat(ts, "mm/dd/yyyy, h:MM:ss TT");
}

export default function GeneratedDate(props) {
  if (props.timestamp) {
    let dateText = formatDate(props.timestamp);
    return <div>Reported {dateText}</div>;
  }
  return <div />;
}
