import React from "react";
import { Navbar } from "react-bootstrap";
import GeneratedDate from "./GeneratedDate";

export default function SubNavBar(props) {
  return (
    <Navbar className="owl-subnav-bar" variant="dark">
      <Navbar.Collapse className="justify-content-begin">
        <Navbar.Text>
          <div className="subbar-text">
            <GeneratedDate timestamp={props.timestamp} />
          </div>
        </Navbar.Text>
      </Navbar.Collapse>
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text>
          <div className="subbar-text">ID#: {props.id}</div>
        </Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  );
}
