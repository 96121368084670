import React from "react";

export default function Footer() {
  return (
    <div>
      <footer className="owl-footer">
        <div className="container">
          <span>© 2024 CallPass, LLC. – all rights reserved. Xirgo® and Xirgo Technologies®
are registered trademarks, and Owlcam™ is a trademark, of Xirgo Technologies, LLC.</span>
        </div>
      </footer>
    </div>
  );
}
